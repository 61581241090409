{
  "all_services": "Alle services",
  "badges": {
    "bestseller": "Bestseller",
    "fast_response": "Svarer hurtigt",
    "full_capacity": "Fuld kapacitet",
    "high_demand": "Høj efterspørgsel",
    "new_vendor": "Ny leverandør",
    "responds_day": "Svarer typisk inden for en dag",
    "responds_few_days": "Svarer typisk indenfor 1-2 dage",
    "responds_few_more_days": "Svarer typisk inden for 3-4 dage",
    "responds_hours": "Svarer typisk inden for et par timer",
    "responds_week": "Svarer typisk inden for en uge",
    "top_vendor": "Top leverandør"
  },
  "blocks": {
    "rating_columns": {
      "name_rated": "{name} bedømte"
    }
  },
  "blog": {
    "confirm": "Bekræft",
    "enter_your_email": "Indtast din e-mail",
    "next_recommended_read": "Næste anbefalede læsning",
    "officeguru_can_store_my_data": "Abonner på vores nyhedsbrev og få førstehåndsinfo om spændende nyheder og features",
    "others_have_read": "Andre har læst",
    "sign_up_for_newsletter": "Tilmeld dig nyhedsbrev"
  },
  "cart": {
    "cancel": "Annuller",
    "cart": "Kurv",
    "cart_changed_verify_content": "Indholdet af din kurv er blevet ændret, sørg venligst for at kontrollere indholdet af kurven, før du foretager et køb.",
    "checkout": "Til kassen",
    "delivery_fee": "Leveringsgebyr",
    "did_you_know": "Vidste du?",
    "discounts": {
      "add": "Tilføj",
      "add_code_failed": "Tilføjelse af kode mislykkedes",
      "cancel": "Annuller",
      "code": "Rabatkode",
      "code_placeholder": "fx SALE23",
      "discount_free_delivery": "Rabat - Gratis levering",
      "discount_n_percent_off": "Rabat - {number} % af",
      "errors": {
        "error_og_discounts_distinct": "Rabatkoden kan kun bruges én gang",
        "error_og_discounts_invalid": "Rabatkoden er ugyldig",
        "error_og_discounts_only_one_each_type": "Du kan kun bruge én af hver type rabatkode"
      },
      "no_longer_available": "Rabat(er) er ikke længere tilgængelig",
      "no_longer_available_description": "Nogle rabatter på din ordre er ikke længere tilgængelige. Fjern dem, og prøv igen.",
      "remove_code_failed": "Koden kunne ikke fjernes",
      "unknown_error": "Ukendt fejl",
      "validating_cart_failed": "Validering af kurven mislykkedes."
    },
    "general_error": "Noget gik galt med din kurv. Kontakt vores support hvis problemet fortsætter.",
    "go_to_cart": "Gå til kurv",
    "go_to_marketplace": "Gå til markedsplads",
    "officeguru_protection": "Officeguru Protection",
    "pricing": "Pris",
    "service_fee": "Service fee (1,79%)",
    "service_fee_percentage": "Servicegebyr ({percentage}%)",
    "subtotal": "Subtotal",
    "total_in_dkk_incl_vat": "Total DKK (inkl. moms)",
    "total_incl_vat": "Total (inkl. moms)",
    "vat": "Moms (25%)",
    "you_can_buy_products_from_vendors": "Du kan købe produkter fra leverandører direkte fra deres profil. Bare gå til markedspladsen og find en leverandør, der sælger produkter.",
    "you_can_only_order_from_one_vendor": "Du kan kun bestille produkter fra én leverandør ad gangen. Hvis du tilføjer dette produkt, vil din nuværende kurv blive tømt."
  },
  "cart.add_to_cart": "Tilføj til kurv",
  "cart.add_to_cart_this_will_empty": "Tilføj til kurven? Dette vil tømme den nuværende kurv",
  "cart.your_cart_is_empty": "Din kurv er tom",
  "checkout": {
    "add_a_not_description": "Hvis du har særlige ønsker, bedes du skrive dem her",
    "add_a_note": "Tilføj note",
    "address": "Adresse",
    "delivery": "Levering",
    "i_want_to_receive_marketing_content_and_newsletters": "Abonner på vores nyhedsbrev og få førstehåndsinfo om spændende nyheder og features",
    "note_placeholder": "fx særlige ønsker eller allergener",
    "optional": "Valgfrit",
    "order_overview": "Ordreoversigt",
    "place_order": "Bekræft bestilling",
    "preferred_delivery_date": "Ønsket leveringsdato",
    "the_desired_delivery_date_can_be_adjusted": "Den ønskede leveringsdato kan justeres af leverandøren.",
    "you_will_receive_an_invoice": "Du modtager en faktura, når din ordre er blevet accepteret."
  },
  "components": {
    "datepicker": {
      "select_a_date": "Vælg en dato"
    },
    "footer": {
      "gazelle_winner": "Børsen Gazelle vinder 7 år i træk."
    },
    "login": "Log ind",
    "search": {
      "search": "Søg"
    },
    "select_dropdown": {
      "select_an_option": "Vælg en mulighed"
    },
    "service_search": {
      "find_a_service": "Søg på service",
      "no_results": "Ingen resultater",
      "popular_services": "Populære services",
      "search": "Søg",
      "zip": "Postnr."
    },
    "signup": "Opret konto"
  },
  "customer": {
    "calendar": {
      "title": "Kalender"
    },
    "home": {
      "title": "Hjem"
    },
    "marketplace": "Marketplace",
    "messages": "Beskeder",
    "orders": {
      "title": "Bestillinger"
    },
    "partners_list": {
      "title": "Leverandører"
    },
    "settings": {
      "company_settings": "Indstillinger"
    },
    "tasks": {
      "title": "Opgaver"
    }
  },
  "customer_signup_form": {
    "already_have_account": "Har du allerede en konto?",
    "button": {
      "submit": "Opret gratis konto"
    },
    "contact_us": "Kontakt os",
    "creating_account": "Oprettelse af konto",
    "customer": {
      "address": {
        "error_required": "Adresse er påkrævet."
      },
      "email": {
        "error_email": "E-mail skal være en gyldig e-mail",
        "error_required": "E-mail er påkrævet.",
        "error_unique": "E-mail bruges allerede."
      },
      "name": {
        "error_required": "Kundenavn er påkrævet.",
        "error_unique": "Navn er allerede taget"
      },
      "person": {
        "error_required": "Navn er påkrævet."
      },
      "zip": {
        "error_between_numeric": "Zip skal være mellem 1050 og 9990",
        "error_integer": "Zip skal kun bestå af tal",
        "error_regex": "Postnummer er ugyldigt for det valgte land",
        "error_required": "Postnummer er påkrævet."
      }
    },
    "generic_error": "Beklager, vi kunne ikke sende formularen. Prøv igen om et par sekunder, eller kontakt os for at få hjælp.",
    "go_to_login": "Gå til login",
    "label": {
      "address": "Adresse",
      "company": "Firma",
      "country": "Land",
      "email": "E-mail",
      "full_name": "Navn",
      "i_have_read_and_accept": "Jeg har læst og accepterer",
      "marketing_content_and_newsletter": "Abonner på vores nyhedsbrev og få førstehåndsinfo om spændende nyheder og features",
      "password": "Adgangskode",
      "phone": "Telefonnummer",
      "terms_at": "Jeg har læst og accepterer <a href=\"/da/terms\" target=\"blank\" rel=\"noopener\">servicevilkårene</a>",
      "terms_of_service": "handelsbetingelserne",
      "zip": "Postnummer"
    },
    "log_in": "Log ind",
    "placeholder": {
      "address": "fx Bankgade 12",
      "company": "fx Fynske Bank",
      "email": "fx hans {'@'} fb.dk",
      "full_name": "fx Hans Petersen",
      "password": "Vi holder det hemmeligt",
      "phone": "fx 45452323",
      "zip": "fx 1224"
    },
    "sign_up_it_is_free": "Opret dig i dag - gratis",
    "start_getting_control": "Kom i gang og få styr på kontordriften i dag.",
    "user": {
      "email": {
        "error_email": "E-mail skal være en gyldig e-mail",
        "error_required": "E-mail er påkrævet.",
        "error_unique": "E-mail bruges allerede."
      },
      "password": {
        "error_required": "Adgangskode er påkrævet."
      }
    },
    "we_are_happy_you_are_part_of_og": "Succes! Vi er glade for at kunne sige, at du nu er en del af Officeguru",
    "you_can_now_log_in": "Du kan nu logge ind og begynde at nyde fordelene ved platformen"
  },
  "forgot_password": "Glemt din adgangskode?",
  "go_to_app": "Gå til app",
  "httpErrors": {
    "base": {
      "contact_us": "kontakt os",
      "go_to_home": "Gå til forsiden",
      "go_to_marketplace": "Gå til markedsplads",
      "go_to_products": "Gå til produkter",
      "go_to_services": "Gå til services",
      "go_to_vendor": "Gå til leverandør",
      "or_if_you_need_help": "Eller hvis du har brug for hjælp"
    },
    "fallthrough": {
      "title": "Vi <em>kan ikke finde den side</em>, du leder efter."
    },
    "generic_500": {
      "description": "Vi arbejder på at løse dette. I mellemtiden, lad os få dig hjem.",
      "title": "Beklager, vi havde en <em>uventet fejl</em>"
    },
    "unknown_product": {
      "description_no_vendor": "Leverandøren findes ikke. Gå til services for at finde andre leverandører, der sælger det produkt, du har brug for.",
      "description_vendor_exists": "Gå til leverandørens profil for at se hvad de tilbyder.",
      "title": "Vi <em>kan ikke finde det produkt</em>, du leder efter."
    },
    "unknown_product_overview": {
      "description_no_vendor": "Leverandøren findes ikke. Gå til services for at finde andre leverandører, der sælger det produkt, du har brug for.",
      "description_vendor_exists": "Denne leverandør sælger ikke produkter. Gå til services for at finde andre leverandører, der sælger det produkt, du søger.",
      "title": "Vi <em>kan ikke finde den produktliste</em>, du leder efter."
    },
    "unknown_service": {
      "description": "Gå til services, for at finde en service der passer til dine behov.",
      "title": "Vi <em>kan ikke finde den service</em>, du leder efter."
    },
    "unknown_vendor": {
      "description": "Gå til markedspladsen for at finde andre leverandører, der passer til dine behov.",
      "title": "Vi <em>kan ikke finde den leverandør</em>, du leder efter."
    }
  },
  "login": {
    "email": {
      "error_email": "E-mail skal være en gyldig e-mail",
      "error_og_credentials": "E-mail eller adgangskode forkert.",
      "error_og_login_user_type_customer": "Denne e-mail tilhører ikke en kundebruger",
      "error_required": "E-mail er påkrævet.",
      "error_unique": "E-mail bruges allerede."
    },
    "password": {
      "error_required": "Adgangskode er påkrævet."
    }
  },
  "officehub": {
    "close": "Luk",
    "managed_by": "Administreret af",
    "managed_by_tooltip": "Forklaring af underkonti",
    "viewing_as_name": "Vises som {name}"
  },
  "pageError": {
    "404_description": "Vi søgte i alle hjørner, men vi kunne ikke finde denne side. Lad os få dig hjem.",
    "404_title": "Beklager, siden findes desværre ikke",
    "500_description": "Vi arbejder på at rette op på dette. I mellemtiden, lad os få dig hjem.",
    "500_title": "Beklager, der er sket en uventet fejl",
    "take_me_home": "Gå til forsiden"
  },
  "product_page": {
    "add_to_cart": "Tilføj til kurv",
    "added_x_to_cart": "{name} er tilføjet til kurven",
    "added_x_y_to_cart": "{count}x {name} er tilføjet til kurven",
    "price_excl_vat": "Pris (ekskl. moms)"
  },
  "request_flow": {
    "add_more": "+ Tilføj flere",
    "address_change_info": "The zip code has changed to {new_zip}. If this is incorrect, go back and change the address.",
    "address_change_info_title": "Address changed",
    "agree_to_vendor_terms": "I agree to the vendor's terms of service",
    "almost_there_sign_up": "Opret en konto eller log ind",
    "back": "Tilbage",
    "billed_after_completion": "Du bliver først faktureret efter opgavens afslutning",
    "call_us": "Ring til os",
    "call_us_for_help": "ring til os for at få hjælp",
    "cancel": "Annuller",
    "choose_file": "Choose file",
    "choose_image": "Choose image",
    "choose_service": "Vælg service",
    "choose_up_to_3": "Vælg op til 3.",
    "confirm_and_send": "Bekræft og send",
    "confirm_your_details_before_we_send": "Bekræft dine oplysninger, før vi sender dem til leverandøren | Bekræft dine oplysninger, før vi sender dem til leverandørerne",
    "continue": "Continue",
    "continue_anyway": "Continue anyway",
    "continue_with_request": "Continue with request",
    "customer_already_has_agreement": "You already have an agreement with this vendor for this service.",
    "days": {
      "friday": "Fre",
      "monday": "Man",
      "saturday": "Lør",
      "sunday": "Søn",
      "thursday": "Tor",
      "tuesday": "Tir",
      "wednesday": "Ons"
    },
    "do_you_already_have_an_account": "Har du allerede en konto?",
    "dont_have_account_already": "Har du ikke en konto?",
    "dont_hesitate_to_call": "Tøv ikke med at ringe til os, vi vil gøre vores bedste for at hjælpe dig.",
    "drag_file_here": "Træk fil(er) her eller klik for at uploade",
    "edit": "Rediger",
    "email": "E-mail",
    "email_placeholder": "navn {'@'} firma.dk",
    "file_too_large": "File is too large. Max file size is 5MB.",
    "file_upload_error": "File upload failed. Please try again.",
    "files": "Files",
    "free_wo_strings": "Det er gratis og uden binding",
    "get_an_offer": "Indhent tilbud",
    "get_offer": "Indhent tilbud",
    "get_offer_for_service": "Få et tilbud på {service}",
    "get_offer_from_vendor": "Få et tilbud fra {vendor}",
    "go_to_chat_support": "gå til chat support",
    "help_us_understand_your_needs": "Help us understand your needs",
    "images": "Images",
    "invalid_address": "Address is invalid",
    "issue_on_our_end_working_on_it": "Det ser ud til, at der var et problem i vores system, mens vi behandlede din anmodning. Vores team er blevet underrettet, og vi arbejder på at løse problemet.",
    "leave": "Leave",
    "leave_description": "Are you sure you want to leave? Your changes will not be saved.",
    "locations_loading": "Henter lokationer...",
    "log_in": "Log ind",
    "logging_in": "Logger ind",
    "login_and_get_offer": "Log ind",
    "max_3_files_can_be_uploaded": "Maximum 3 files can be uploaded",
    "max_3_images_can_be_uploaded": "Maximum 3 images can be uploaded",
    "max_amount_of_vendors_selected": "You have selected the maximum amount of vendors",
    "max_vendors_reached_warning": "Du kan ikke vælge mere end {count} leverandører. Fravælg en, før du vælger en anden.",
    "min_pax_count": "Min. antal {count}",
    "n_additional_vendors": "Du kan vælge {n} leverandører mere, for at få flere tilbud at vælge imellem.",
    "new_on_officeguru": "Ny på Officeguru",
    "next": "Næste",
    "no_more_vendors": "Du kan ikke vælge flere leverandører.",
    "no_vendors_deliver_to_address": "Ingen leverandører leverer til {address}",
    "no_vendors_description": "Try to find a vendor at another address or contact us for help.",
    "no_vendors_found": "No vendors found",
    "now_drop": "Lad nu filen(erne) gå for at uploade",
    "offer_for_service": "Indhent tilbud på {service}",
    "optional": " (valgfrit)",
    "or": "eller",
    "password": "Adgangskode",
    "request_sent_description": "We will contact you soon to confirm your request.",
    "request_sent_title": "Your request has been sent!",
    "search_address_or_select": "Search address or select from list",
    "select_n_vendors": "Vælg {count} leverandør(er)",
    "select_service": "Vælg service",
    "select_service_for_offer": "Vælg den service du vil have tilbud på.",
    "select_service_placeholder": "Vælg service",
    "select_up_to_3": "Select up to 3.",
    "select_vendor": "Vælg leverandør",
    "select_vendors": "Vælg leverandør(er)",
    "select_vendors_near": "Vælg leverandører omkring",
    "selected": "Valgte",
    "selected_n_vendors": "{count} vendors selected",
    "selected_vendors_description": "Select the vendors you want to receive offers from.",
    "selected_vendors_title": "Select vendors",
    "sending_request": "Hæng på, vi sender din forespørgsel til vores leverandører",
    "sign_up": "Opret konto",
    "skip": "Skip",
    "skip_this_step": "Skip this step",
    "something_went_wrong": "Something went wrong",
    "something_went_wrong_description": "We couldn't send your request. Please try again.",
    "something_went_wrong_title": "Something went wrong",
    "sorry_something_went_wrong": "Beklager, noget gik galt!",
    "sure_you_want_to_cancel": "Luk forespørgslen? Du mister oplysningerne, som du har indtastet.",
    "tell_us_about_your_needs": "Tell us about your needs",
    "try_again": "Prøv igen",
    "trying_again": "Prøver igen...",
    "up_to_n_vendors": "Vælg op til {n} leverandører.",
    "upload_description": "You can upload up to 3 files or images below (max 5MB each)",
    "upload_error": "Upload error",
    "vendors_removed_info": "{count} leverandør leverer ikke til din adresse. Du kan vælge en anden nedenfor. | {count} leverandører leverer ikke til din adresse. Du kan vælge andre nedenfor.",
    "view_profile": "Se profil",
    "we_might_have_vendors_that_deliver_to_other_location": "Vi har muligvis leverandører, der leverer til dine andre lokationer - vælg venligst en placering:",
    "we_will_ask_few_questions": "Svar på nogle få spørgsmål og få op til 3 uforpligtende tilbud.",
    "what_are_you_looking_for": "What are you looking for?",
    "what_vendors_would_you_like_offers_from": "Hvilke leverandører ønsker du tilbud fra?",
    "which_location_do_you_need_service_for": "Which location do you need service for?",
    "which_location_to_deliver_to": "Hvilken adresse vil du have din levering til?",
    "which_vendors_in_address_would_you_like": "Hvilke leverandører på {address} vil du have tilbud fra?",
    "you_can_select_n_more_vendors": "You can select {count} more vendor | You can select {count} more vendors",
    "you_would_like_offers_from": "du vil have tilbud fra"
  },
  "search": {
    "clear_all": "Ryd alle",
    "explore_marketplace": "Udforsk markedspladsen",
    "filters": {
      "all_services": "Alle services",
      "can_add_one_vendor": "Du kan tilføje én leverandør mere.",
      "can_add_three_vendors": "Du kan tilføje tre leverandører.",
      "can_add_two_vendors": "Du kan tilføje to leverandører mere.",
      "categories": "Kategorier",
      "close": "Luk",
      "deliver_to_zip": "Leverer til postnummer",
      "filter_by_tags": "Tags",
      "filters": "Filtre",
      "minimum_pax": "Antal spisende",
      "minimum_pax_placeholder": "fx 5",
      "service": "Service",
      "show": "Vis",
      "sort_by": "Sorter efter",
      "vendors": "Leverandør",
      "your_zip": "Dit postnummer",
      "zip": "Postnummer"
    },
    "find_anything": "Søg på markedspladsen",
    "get_offer": "Indhent tilbud",
    "load_more": "Indlæs flere",
    "loading": "Indlæser",
    "marketplace": "Markedsplads",
    "max_vendors_reached_warning": "Du kan ikke vælge mere end {count} leverandører. Fravælg en, før du vælger en anden.",
    "min_count_pax": "Min {count} pax",
    "min_count_pax_short": "Antal spisende",
    "no_min_pax": "Ingen min. pax",
    "no_price": "Ingen pris",
    "no_products_match_your_filters": "Ingen produkter matcher dine filtre og søgetermer.",
    "no_results_found": "Ingen resultater fundet",
    "no_services_match_your_filters": "Ingen services matcher dine filtre og søgetermer.",
    "no_vendors_match_your_filters": "Ingen leverandører matcher dine filtre og søgetermer.",
    "not_finding_what_you_are_looking_for": "Vi kan hjælpe dig med at finde det du leder efter",
    "partners": "Leverandører",
    "price": "Pris",
    "price_from_per_pax": "Fra {price}/pax",
    "price_from_per_pax_fallback": "Fra {price}/pax",
    "price_range_per_pax": "{from}- {to}/pax",
    "products": "Produkter",
    "remove_filters": "Ryd alle filtre",
    "search": "Søg",
    "search_placeholder": "Søg på markedspladsen",
    "search_results": "Søgeresultater",
    "search_results_for_term": "Søgeresultater for \"{term}\"",
    "services": "Services",
    "show_all_results": "Vis alle resultater",
    "show_all_tags_count": "Vis alle ({count})",
    "something_went_wrong": "Noget gik galt",
    "sorting": {
      "alphabetical": "Alfabetisk",
      "price_high_to_low": "Pris høj til lav",
      "price_low_to_high": "Pris lav til høj",
      "top_rated": "Bedst bedømt"
    },
    "start_typing_to_search": "Begynd at skrive for at søge...",
    "tag_search_placeholder": "Søg tags",
    "type": "Type",
    "vendor_name": "Leverandør",
    "vendor_not_matching_filters": "Den valgte leverandør opfylder ikke kriterierne for de anvendte filtre.",
    "vendor_or_product_not_found": "Vi kunne desværre ikke finde produktet eller leverandøren du ledte efter. Brug søgefeltet til at finde det du har brug for på markedspladsen.",
    "vendor_or_product_not_found_header": "Ikke fundet",
    "vendors": "Leverandører",
    "we_can_help_finding_the_right_match_for_you": "Vi kan hjælpe med at finde det rigtige match til dig",
    "we_could_not_find_any_results": "Vi kunne ikke finde nogen resultater, der matcher dine filtre og søgetermer. Prøv en anden søgning eller:",
    "zip_short": "Postnummer"
  },
  "service_categories": {
    "cleaning": "Rengøring",
    "coffee": "Kaffe",
    "events_and_celebrations": "Events & Fejringer",
    "food_and_drinks": "Mad & drikke",
    "fruit": "Frugt",
    "handyman_services": "Handymantjenester",
    "office_and_wellness": "Kontor & Velvære"
  },
  "service_page": {
    "a_quick_chat_to_hear_more_about_your_company": "En hurtig snak for at høre mere om din virksomheds ønsker",
    "always_available": "Høj kvalitet og gode priser",
    "availability_is_a_prerequisite_for_a_good_partnership": "Du kan nemt indhente tilbud og sammenligne priser. Alle leverandører er interesseret i en femstjernet rating, hvilket betyder, at du får høj kvalitet.",
    "click_to_try_again": "Klik her for at prøve igen",
    "company": "Firma",
    "contact": "Kontakt",
    "contact_form": "Kontaktformular",
    "contact_me_about_offers_today": "Kontakt mig om tilbud i dag",
    "contact_me_today": "Kontakt mig i dag",
    "count_ratings": "{count} anmeldelser",
    "create_account": "Opret konto",
    "create_your_account": "Opret din konto",
    "deliver_to_zip": "Leverer til postnummer",
    "delivered_by": "Leveret af",
    "delivers_to_you": "Leverer til dig",
    "delivers_to_zip": "Leverer til {zip}",
    "description": "Beskrivelse",
    "discover_all_vendors": "Se alle leverandører",
    "email": "E-mail",
    "error_loading_vendors": "Beklager, der er sket en fejl ved indlæsning af leverandørerne.",
    "faq": "FAQ",
    "frequently_asked_questions": "Ofte stillede spørgsmål",
    "full_name": "Fulde navn",
    "get_a_cleaning_deal_that_is_easy_to_manage": "Alt kommunikation er samlet i én fælles indbakke, som dine kollegaer også kan tilgå, så I nemt kan koordinere og holde overblik over aftaler.",
    "get_offers": "Få tilbud",
    "get_started": "Kom i gang",
    "get_your_offer_now": "Få dit tilbud nu",
    "high_customer_satisfaction": "Én kontrakt og én faktura",
    "i_accept_officeguru_stores_information": "Jeg accepterer, at Officeguru gemmer mine oplysninger og kan kontakte mig.",
    "input_zip": "Postnr.",
    "learn_more": "Mere information",
    "marketplace": "Markedsplads",
    "min_pax_count": "Min. antal {count}",
    "near_you": "nær dig",
    "no_ratings": "Ingen anmeldelser endnu",
    "no_vendors_can_deliver_to_your_zip": "Ingen leverandører kan levere denne service til dit postnummer",
    "not_ready_for_an_offer_yet": "Er du ikke klar til et tilbud endnu? Måske vil du gerne høre mere. Intet problem! Book en uforpligtende snak med en af vores specialister.",
    "officeguru_protection": "Officeguru Protection",
    "officeguru_protection_description": "Med Officeguru er du garanteret fremragende service, høj kvalitet og konkurrencedygtige priser. Vores godkendte leverandører og brugervenlige vilkår gør det ubesværet at skifte mellem dem. Vi tilbyder dækning ved skader (kompensation er underlagt forsikringsgrænser), og vores Customer Success-team er altid klar til at yde support.",
    "officeguru_trains_cleaning_partners": "Alle vores leverandører har ens handelsbetingelser, og uanset om du har en eller flere services, får du én samlet faktura fra Officeguru.",
    "or": "Eller",
    "others_also_bought": "Andre købte også",
    "others_bought": "Andre har købt",
    "phone_number": "Telefon",
    "questions": "Spørgsmål?",
    "read_more": "Læs mere",
    "review_your_offer": "Gennemgå dit tilbud",
    "reviews": "Anmeldelser",
    "reviews_of_service": "Anmeldelser af {service}",
    "reviews_of_service_x": "Anmeldelser af {service}",
    "save_time_on_administration": "Spar tid på administration",
    "service_x_vendors": "{service} leverandører",
    "sign_up_get_offers": "Opret konto og få tilbud fra lokale leverandører",
    "something_went_wrong": "Noget gik galt",
    "sort_alphabetically": "Sorter alfabetisk",
    "sort_by_top_rated": "Sorter efter bedømmelse",
    "the_vendor_will_send_you_an_offer": "Leverandøren sender dig et tilbud, der passer til dine behov",
    "type_vendors": "Leverandører af {type}",
    "valid_email": "E-mail skal være et gyldigt format.",
    "vendor_tags": "Tags",
    "vendors": "Leverandører",
    "view": "Se profil",
    "want_to_get_started_by_yourself": "Vil du komme i gang selv?",
    "we_have_received_your_request": "Vi har modtaget din anmodning",
    "we_will_contact_you_in_1_2_days": "Vi kontakter dig inden for 1-2 dage",
    "why_officeguru_is_right_for_you": "Hvorfor Officeguru er det rigtige for dig",
    "you_will_be_contacted_by_specialists": "Du vil blive kontaktet af en af vores specialister"
  },
  "shared": {
    "loading_countries": "Indlæser lande...",
    "n_current_of_n_total": "{current} af {total}",
    "showing_n_to_m_of_total_resource": "Viser {from} til {to} af {total} {resource}"
  },
  "vendor_page": {
    "about": "Om os",
    "account_owner": "Ansvarlig",
    "all_products": "Alle produkter",
    "answer_from_name": "Svar fra {name}",
    "average_rating": "Gennemsnitlig bedømmelse",
    "categories": "Kategorier",
    "click_here_to_refresh": "Klik her for at opdatere.",
    "close": "Luk",
    "count_ratings": "{count} anmeldelser",
    "delivers_to_you": "Leverer til dig",
    "employees": "Medarbejdere",
    "example_menu": "Menu eksempel",
    "faq": "FAQ",
    "faq_company_certifications": "Hvilke certificeringer har din virksomhed?",
    "faq_company_consistency_and_quality": "Hvordan sikrer din virksomhed ensartet og høj kvalitet?",
    "faq_company_sustainability": "Hvordan arbejder din virksomhed med bæredygtighed?",
    "faq_kind_of_customers": "Hvilken slags kunder har du arbejdet med?",
    "faq_working_environment": "Hvordan sikrer din virksomhed et godt arbejdsmiljø?",
    "filter_by_item": "Filtrer efter {item}",
    "filter_by_service": "Filtrer efter service",
    "filtering_by_item": "Filtrerer efter {item}",
    "filtering_by_label": "Filtrerer efter {label}",
    "filters": "Filtre",
    "guruscore_tooltip": "Guruscoren beregnes ud fra kundernes anmeldelser på markedspladsen og bruger et bayesiansk gennemsnit for at gøre det mere sammenligneligt.",
    "latest_5_reviews": "Seneste 5 anmeldelser",
    "latest_count_reviews": "Seneste {count} anmeldelser",
    "latest_reviews": "Seneste anmeldelser",
    "load_count_more": "Indlæs {count} mere",
    "loading_of_products_failed": "Indlæsning af produkter mislykkedes.",
    "marketplace": "Markedsplads",
    "minimum_order": "Minimum ordre",
    "no_reviews_match_your_filters": "Ingen anmeldelser matcher dine nuværende filtre",
    "on_date": "Den {date}",
    "operational_areas": "Operationelle områder",
    "organic_badge": "Økologimærke",
    "organic_bronze": "30-60% økologisk",
    "organic_gold": "90-100% økologisk",
    "organic_silver": "60-90% økologisk",
    "overview": "Oversigt",
    "price": "Pris",
    "products": "Produkter",
    "products_description": "Find produkter til dit kontor.",
    "rated": "Anmeldte",
    "rating_by_percentage": "Filtrer efter bedømmelse",
    "ratings": "Anmeldelser",
    "review": "Anmeldelse",
    "services": "Services",
    "services_provided": "Udbudte services",
    "smiley_report": "Smiley rapport",
    "star_rating_x": "{rating}-stjerners bedømmelse",
    "tags": "Tags",
    "terms_of_payment": "Betingelser for betaling",
    "terms_of_payment_description": "Services betales månedligt og forfalder 8 dage fra fakturadato. Hos Officeguru er vilkårene og betingelserne de samme for alle services.",
    "vendors": "Leverandører",
    "verification_description": "Verifikationsmærket betyder, at vi har godkendt denne leverandør og kan garantere en god kvalitet.",
    "verification_details": "Verifikation",
    "verified": "Verificeret",
    "view": "Se",
    "view_all": "Se alle",
    "view_all_images": "Se alle billeder",
    "view_menu": "Se menu",
    "view_terms_of_payment": "Se betalingsbetingelser",
    "would_hire_again": "Ville benytte igen",
    "years_in_business": "År siden etablering",
    "zip_ranges": "Postnumre"
  },
  "vendor_signup_form": {
    "apply_for_free": "Vi kontakter dig hurtigst muligt.",
    "button": {
      "submit": "Kom gratis i gang"
    },
    "contact_us": "Kontakt os",
    "generic_error": "Beklager, vi kunne ikke sende formularen. Prøv igen om et par sekunder, eller kontakt os for at få hjælp.",
    "get_new_customers_today": "Kom i gang allerede i dag",
    "label": {
      "company": "Firma",
      "country": "Land",
      "email": "E-mail",
      "marketing_content_and_newsletter": "Abonner på vores nyhedsbrev og få førstehåndsinfo om spændende nyheder og features",
      "name": "Navn",
      "phone": "Telefonnummer",
      "what_services": "Hvilket services tilbyder I?"
    },
    "partner": {
      "description": {
        "error_required": "Partnerbeskrivelse er påkrævet."
      },
      "name": {
        "error_required": "Partner navn er påkrævet.",
        "error_unique": "Partnernavn er allerede taget"
      }
    },
    "placeholder": {
      "company": "fx Fynske Bank",
      "email": "fx hans {'@'} example.com",
      "name": "fx Hans Petersen",
      "phone": "fx 45452323",
      "what_services": "fx frokostordning, rengøring"
    },
    "thanks_for_application": "Mange tak! Vi har modtaget din ansøgning",
    "user": {
      "email": {
        "error_email": "E-mail skal være en gyldig e-mail",
        "error_required": "E-mail er påkrævet.",
        "error_unique": "E-mail bruges allerede."
      },
      "phone": {
        "error_required": "Telefonnummer er påkrævet."
      }
    },
    "we_will_contact_you": "Vi kontakter dig hurtigst muligt."
  }
}
